<template>
  <div class="grid-item-wrap">
<!--    <img :src="data.item.image_url" alt="Bottle" class="grid-bottle">-->
    <div class="grid-item item-col px-1 pb-8 pointer" dark
         v-bind:class="{ highlighted: data.item.promotion == 1 }">
      <v-btn class="ma-2 list-button-3" tile depressed fab dark small color="black" @click.stop="addToFav(data.item)">
        <v-icon dark color="white" v-if="isFav(data.item.id)">
          mdi-check
        </v-icon>
        <v-icon dark color="white" v-else>
          mdi-checkbox-marked
        </v-icon>
      </v-btn>
      <div class="image-wrap-3">
        <img :src="data.item.image_url" alt="Bottle" class="grid-bottle">
      </div>
      <h2 v-if="data.item.title.length < 30" class="white--text w-100 text-center">
        {{ data.item.title }}
      </h2>
      <h4 v-else class="white--text w-100 text-center">
        {{ data.item.title }}
      </h4>
      <p class="white--text w-100 ml-2 text-center" style="opacity: 37% !important;" v-if="data.item.winery">
        {{ data.item.winery.name }}</p>
      <div class="prices d-flex mb-3">
        <div class="d-flex justify-center align-center mr-3">
          <v-icon color="#d7c194" v-if="data.item.winery" large>mdi-bottle-wine</v-icon>
          <v-img
              v-if="data.item.product_type_id == 4"
              src="@/assets/img/whiskey.svg"
              style="width: 25px !important"
              height="25px"
          ></v-img>
          <span class="gold-text">
            {{ data.item.price_bottle }}{{ client.currency }}
<!--            <template v-if="conversionRate">-->
<!--              <br>-->
<!--              {{ calculatePrice(data.item.price_bottle) }}{{ 'kn' }}-->
<!--            </template>-->
          </span>
        </div>
        <template v-if="data.item.price_glass">
          <span class="gold-text" v-if="data.item.winery">-</span>
          <div class="d-flex justify-center align-center ml-3" v-if="data.item.winery">
            <v-icon color="#d7c194" large>mdi-glass-wine</v-icon>
            <span class="gold-text">
              {{ data.item.price_glass }}{{ client.currency }}
<!--              <template v-if="conversionRate">-->
<!--                <br>-->
<!--                {{ calculatePrice(data.item.price_glass) }}{{ 'kn' }}-->
<!--              </template>-->
            </span>
          </div>
          <span class="gold-text" v-if="data.item.product_type_id == 4">-</span>
          <div class="d-flex justify-center align-center ml-3" v-if="data.item.product_type_id == 4">
            <v-icon color="#d7c194" size="20" left>fas fa-glass-whiskey</v-icon>
            <span class="gold-text">
              {{ data.item.price_glass }}{{ client.currency }}
<!--              <template v-if="conversionRate">-->
<!--              <br>-->
<!--              {{ calculatePrice(data.item.price_glass) }}{{ 'kn' }}-->
<!--            </template>-->
            </span>
          </div>
        </template>
      </div>
      <p class="white--text text-center w-100 mb-0" style="opacity: 37% !important;" v-if="!data.item.winery">
        <span v-for="(filter, index) in data.item.filters" :key="index">
          {{ filter.title }}
          <template v-if="index < data.item.filters.length - 1">,</template>
        </span>
      </p>
      <div class="amount w-100 text--white d-flex justify-space-between flex-center">
        <v-btn class="ma-2" :disabled="data.amount == 1 ? true : false"
               tile depressed fab dark small color="black" @click.stop="subAmount">
          <v-icon dark color="rgba(255, 255, 255, .6)">
            mdi-minus
          </v-icon>
        </v-btn>
        <h3 class="white--text">{{ $t('amount') }} {{ data.amount }}</h3>
        <v-btn class="ma-2"
               tile depressed fab dark small color="black" @click.stop="addAmount">
          <v-icon dark color="rgba(255, 255, 255, .6)">
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <template v-if="data.item.price_glass && data.item.price_bottle">
        <div class="amount w-100 text--white d-flex justify-space-between flex-center">
          <v-row align="center" justify="center">
            <v-col cols="6" class="text-right">
              <v-btn :color="data.dose === 'glass' ? '#8a7859' : '#d7c194'" width="100" light @click="selectDose('glass')" class="text-none">
                <v-icon color="black" small left v-if="data.item.winery">mdi-glass-wine</v-icon>
                <v-icon color="black" size="10" left v-else>fas fa-glass-whiskey</v-icon>
                {{ $t('glass_1') }}
                <v-icon dark color="green" small right v-if="data.dose === 'glass'">mdi-check-circle</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn :color="data.dose === 'bottle' ? '#8a7859' : '#d7c194'" width="100" light @click="selectDose('bottle')" class="text-none">
                <v-icon color="black" small left>mdi-bottle-wine</v-icon>
                {{ $t('bottle') }}
                <v-icon dark color="green" small right v-if="data.dose === 'bottle'">mdi-check-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <template>
        <div class="amount w-120 text--white d-flex justify-space-between flex-center pt-6">
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center">
              <v-dialog v-model="noteDialog" class="order-dialog" max-width="400">
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="#d7c194" depressed class="main-btn mb-4 text-right"
                  >
                    {{ $t('add_note') }}
                  </v-btn>
                </template>
                <div>
                  <div class="pa-4 text-center">
                    <h3>Dodajte napomenu za ovaj proizvod</h3>
                  </div>
                  <div class="pa-4">
                    <v-textarea
                        v-model="note"
                        :label="$t('note')"
                        color="black"
                        outlined
                    ></v-textarea>
                  </div>
                  <div class="pa-4">
                    <div class="d-flex justify-space-between flex-center pt-6">
                      <v-btn @click="note='',noteDialog=false" color="#d7c194" depressed class="main-btn  mb-1 align-self-end" width="120" height="46"
                             type="submit">
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn  @click="addNote" color="#d7c194" depressed class="main-btn  mb-1 align-self-end" width="120" height="46"
                                                                            type="submit">
                      {{ $t('confirm') }}
                    </v-btn>
                    </div>

                  </div>
                </div>
              </v-dialog>

            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "GridItem",
  props: ['data'],
  data() {
    return {
      amount: 1,
      product: null,
      type_dose: null,
      note: '',
      noteDialog: false,
    }
  },
  created() {
    let arr = this.$store.getters.getCart
    arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null}})
  },
  mounted() {
    this.product = {[this.data.item.id] : {'amount': this.amount, 'note': this.note}}
  },
  computed: {
    client() {
      return this.$store.getters.getClient
    },
    conversionRate(){
      return this.$store.getters.getClient.dual_price
    }
  },
  methods: {
    async addNote() {
      await this.$store.dispatch('setFavoriteProductById', {product_id: this.data.item.id, amount: this.data.amount, dose: this.data.dose, note: this.note})
      // this.amount++;
      this.product = {[this.data.item.id] : {'amount': this.amount, 'note': this.note}};
      let arr = this.$store.getters.getCart;
      let index = -1;
      if(arr.length) {
        for (let i = 0; i < arr.length; i++) {
          let el = arr[i]
          if (this.data.item.id == Object.keys(el)[0]) {
            index = i
          }
        }
      }

      if (index > -1) {
        arr.splice(index, 1)
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose, 'note': this.note}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null,'note': this.note}})
      } else {
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose,'note': this.note}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null,'note': this.note}})
      }
      this.note = "";
      this.noteDialog = false;
    },

    async addAmount() {
      await this.$store.dispatch('setFavoriteProductById', {product_id: this.data.item.id, amount: this.data.amount+1, dose: this.data.dose, note: this.data.note})
      this.amount = this.amount+1
      this.product = {[this.data.item.id] : {'amount': this.amount}}
      let arr = this.$store.getters.getCart;
      let index = -1;
      if(arr.length) {
        for (let i = 0; i < arr.length; i++) {
          let el = arr[i];
          if (this.data.item.id == Object.keys(el)[0]) {
            index = i;
          }
        }
      }

      if (index > -1) {
        arr.splice(index, 1)
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null}})
      } else {
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null}})
      }
    },
   async subAmount() {
      await this.$store.dispatch('setFavoriteProductById', {product_id: this.data.item.id, amount: this.data.amount-1, dose: this.data.dose})
      this.amount = this.amount-1
      let arr = this.$store.getters.getCart;
      let index = -1;
      if(arr.length) {
        for (let i = 0; i < arr.length; i++) {
          let el = arr[i]
          if (this.data.item.id == Object.keys(el)[0]) {
            index = i
          }
        }
      }

      if (index > -1) {
        arr.splice(index, 1)
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null}})
      } else {
        if(this.type_dose)
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
        else
          arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': null}})
      }
    },
    async selectDose(dose) {
      this.type_dose = dose
      let arr = this.$store.getters.getCart;
      let index = -1;
      if(arr.length) {
        for (let i = 0; i < arr.length; i++) {
          let el = arr[i]
          if (this.data.item.id == Object.keys(el)[0]) {
            index = i
          }
        }
      }

      if (index > -1) {
        arr.splice(index, 1)
        arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
      } else {
        arr.push({[this.data.item.id] : {'amount': this.amount, 'dose': this.type_dose}})
      }
      await this.$store.dispatch('setFavoriteProductById', {product_id: this.data.item.id, amount: this.data.amount, dose: dose})
    },
    async addToFav(obj) {
      let arr = this.$store.getters.favIds;
      let index = arr.indexOf(obj.id);

      if (index > -1) {
        let arrCart = this.$store.getters.getCart;
        for (let i = 0; i < arrCart.length; i++) {
          let el = arrCart[i]
          if (this.data.item.id == Object.keys(el)[0]) {
            arrCart.splice(i, 1)
          }
        }
        arr.splice(index, 1)
      } else {
        arr.push(obj.id)
      }
      if (this.isFav(this.data?.item?.id)){
        await this.$store.dispatch('removeFromFav', {product_id: this.data.item.id})
      }else{
        await this.$store.dispatch('addToFav', {product_id: this.data.item.id})
      }
    },
    calculatePrice(price){
      return (price/this.conversionRate).toFixed(2).toString()
    },
    isFav(id) {
      if (this.$store.getters.favs.some(item => item.id === id)) {
        return false;
      }
      return true;
    },
  }
}
</script>
<style lang="scss" scoped>
.grid-item-wrap {
  position: relative;
  height: 100%;
}

.grid-item {
  background-color: #191c21;
  border: 1px solid #272c33;
  border-radius: 4px;
  width: 100% !important;
  transition: .3s ease;
  position: relative;
  display: flex;
  margin-top: 150px;
  justify-content: flex-end !important;
  height: 100%;
}

.grid-bottle {
  width: auto !important;
  max-height: 35vh;
  max-width: 30vh;
  position: absolute;
  top: 0;
  z-index: 2;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.item-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.list-button {
  position: absolute;
  z-index: 2;
  left: 5px;
  top: 5px;
}

.list-button-3 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0px;
  bottom: 5px;
}

.image-wrap-3 {
  .grid-bottle {
    margin-top: -15vh;
  }
}

</style>
